import Head from "next/head";
import { motion } from "framer-motion";
import NavBar from "@/components/NavBar";
import SearchBar from "@/components/SearchBar";
import ContactForm from "@/components/ContactForm";
import FooterSection from "@/components/FooterSection";
import GlossarySection from "@/components/GlossarySection";
import SideSection from "@/components/SideSection";
import { useState } from "react";

export default function Home() {  
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <>
      <Head>
        <title>Appran Glossary - Home</title>
        <meta
          name="description"
          content="Welcome to our glossary website. Find definitions and information about various terms."
        />
        <meta name="keywords" content="glossary, definitions, terms, information" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="Headbg text-white">
        <NavBar />
        <SearchBar onSearch={handleSearch} search={searchTerm} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-12 col-12">
            <div className="costMargin">
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                <GlossarySection searchTerm={searchTerm} />
              </motion.div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12">
            <motion.div initial={{ x: -100, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.5, delay: 0.2 }}>
              <SideSection />
            </motion.div>
          </div>
        </div>
        <div className="container">
          <motion.div initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, delay: 0.4 }}>
            <ContactForm />
            <FooterSection />
          </motion.div>
        </div>
      </div>
    </>
  );
}
